import type { Palette } from '@mui/material/styles'
import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'

export type SummaryStatProps = {
    label: React.ReactNode
    value: string | number | null | undefined
    isLoading: boolean
    type?: 'info' | 'alert' | 'success'
    minWidth?: number
    width?: number
    height?: string | number
    emptyVal?: string | number
    fontSize?: number
    labelColor?: string
    textAlign?: 'left' | 'center' | 'right'
}

const mapTypeToColor = (type: SummaryStatProps['type'], muiPalette: Palette) => {
    const colors = {
        info: muiPalette.callOut.info,
        alert: muiPalette.callOut.alert,
        success: muiPalette.callOut.success,
    }
    if (!type) return 'info'
    return colors[type]
}

const SummaryStat = ({
    label,
    value,
    isLoading,
    width,
    minWidth = 10,
    height = 'auto',
    type = 'info',
    emptyVal = 'N/A',
    fontSize = 18,
    labelColor = 'text.primary',
    textAlign,
}: SummaryStatProps): JSX.Element => (
    <Paper
        sx={({ spacing, palette }) => ({
            backgroundColor: mapTypeToColor(type, palette),
            padding: spacing(0.5, 1.0),
            display: 'flex',
            alignItems: 'center',
            minWidth,
            height,
            minHeight: height,
            justifyContent: 'center',
            width,
            overflow: 'hidden',
        })}
        elevation={0}
    >
        <Typography
            variant="h5"
            sx={({ spacing }) => ({
                paddingRight: spacing(1),
                flexGrow: 0,
                fontSize,
                whiteSpace: 'noWrap',
            })}
        >
            {isLoading && <Skeleton sx={{ width: { xs: 16, md: 50 } }} />}
            {!isLoading && (value === null || value === undefined ? emptyVal : value)}
        </Typography>
        <Typography
            color={labelColor}
            variant="caption"
            align={textAlign || (width ? 'left' : 'center')}
            sx={{ maxWidth: 75, lineHeight: 1.17 }}
        >
            {label}
        </Typography>
    </Paper>
)

export default SummaryStat
