'use client'

import React from 'react'
import Box from '@mui/material/Box'
import { Skeleton } from '@mui/material'
import { useBreakPoints } from '@/lib/hooks'
import { teamStatsColorScale } from '@/lib/utils/colors'
import { isNumber } from '@/lib/utils/math'

type TeamHeaderStatsProps = {
    stats: DTO.TeamBasicStats | undefined
    alignVertical: boolean
    isLoading: boolean
}

const offStats = (stackVert: boolean): { key: keyof DTO.TeamBasicStats; label: string }[] => [
    { key: 'offRatingRank', label: stackVert ? 'Rtg' : 'Off Rtg' },
    { key: 'efgRank', label: 'eFG%' },
    { key: 'orbRateRank', label: stackVert ? 'RB%' : 'ORB%' },
    { key: 'tovRateRank', label: 'TOV%' },
    { key: 'ftRateRank', label: 'FTr' },
]

const defStats: { key: keyof DTO.TeamBasicStats; label: string }[] = [
    { key: 'defRatingRank', label: 'Def Rtg' },
    { key: 'efgOppRank', label: 'eFG%' },
    { key: 'drbRateRank', label: 'DRB%' },
    { key: 'tovRateOppRank', label: 'TOV%' },
    { key: 'ftRateOppRank', label: 'FTr' },
]

const TeamHeaderStats = ({ stats, alignVertical, isLoading }: TeamHeaderStatsProps): JSX.Element => {
    const { isMobile, isTablet } = useBreakPoints()
    const stackVert = isMobile || isTablet || alignVertical
    const displayRanks = stats?.numTeams && stats.numTeams >= 30
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: stackVert ? 'column' : 'row',
            }}
        >
            {[offStats(stackVert), defStats].map((s, i) => (
                <Box
                    key={i === 0 ? 'off' : 'def'}
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    {!stackVert && (
                        <Box sx={{ fontSize: '14px', color: 'gray', marginBottom: 0.5, fontWeight: 'medium' }}>
                            {i === 0 ? 'OFFENSE' : 'DEFENSE'}
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            borderRight: i === 0 && !stackVert ? '1px solid black' : undefined,
                            borderBottom: i === 0 && stackVert ? '1px solid black' : undefined,
                        }}
                    >
                        {stackVert && (
                            <Box sx={{ fontSize: '14px', width: '30px', color: 'gray', fontWeight: 'medium' }}>
                                <Box sx={{ height: '21px', display: 'block' }}> {i === 0 ? '' : 'Def.'} </Box>
                                <Box sx={{ height: '21px', display: i === 1 ? 'none' : 'block' }}>
                                    {i === 0 ? 'Off.' : ''}
                                </Box>
                            </Box>
                        )}

                        {s.map((d, j) => {
                            const colors = teamStatsColorScale({ value: Number(stats?.[d.key]) })
                            return (
                                <Box
                                    key={d.key}
                                    sx={{
                                        width: '55px',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        color: 'black',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            fontWeight: 'bold',
                                            display: i === 1 && stackVert ? 'none' : 'block',
                                        }}
                                    >
                                        {d.label}
                                    </Box>
                                    <Box
                                        sx={{
                                            backgroundColor:
                                                isNumber(stats?.[d.key]) && displayRanks
                                                    ? colors.backgroundColor
                                                    : undefined,
                                            color: isNumber(stats?.[d.key]) && displayRanks ? colors.color : undefined,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderTopRightRadius: stackVert && j === 4 && i === 0 ? '3px' : undefined,
                                            borderBottomRightRadius:
                                                stackVert && j === 4 && i === 1 ? '3px' : undefined,
                                            borderTopLeftRadius: stackVert && j === 0 && i === 0 ? '3px' : undefined,
                                            borderBottomLeftRadius: stackVert && j === 0 && i === 1 ? '3px' : undefined,
                                        }}
                                    >
                                        {isLoading ? (
                                            <Skeleton width={35} height={20} />
                                        ) : displayRanks ? (
                                            stats[d.key]
                                        ) : (
                                            '--'
                                        )}
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}
export default TeamHeaderStats
